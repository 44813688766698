import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .whatsapp {
    position: fixed;
    right: 0.8rem;
    bottom: 5rem;

    border-radius: 50%;
    background-color: #3058a4;

    &:hover {
      transform: scale(1.1);
      transition: ease-in 0.2s;
    }
  }
`;

export const Content = styled.div``;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1048px) {
    display: none;
  }
`;

export const Flex = styled.div`
  display: none;

  @media (min-width: 1048px) and (max-width: 1400px) {
    display: flex;
    justify-content: center;
    gap: 1rem;

    width: 100%;
    padding: 0 1rem;

    .support {
      width: 460px;
      height: 800px;

      div {
        width: 460px;
      }
    }
  }

  @media (min-width: 1401px) {
    display: flex;
    justify-content: center;
    gap: 1rem;

    width: 100%;
    padding: 0 1rem;

    .support {
      width: 300px;
      height: 800px;

      div {
        width: 300px;
      }
    }
  }
`;
